<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Tên đăng nhập -->
          <validation-provider
            #default="validationContext"
            name="Tên đăng nhập"
            rules="required"
          >
            <b-form-group
              label="Tên đăng nhập"
              label-for="ten_dang_nhap"
            >
              <b-form-input
                id="ten_dang_nhap"
                v-model="userData.username"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Tên đăng nhập vào phần mềm"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Mật khẩu -->
          <validation-provider
            #default="validationContext"
            name="Mật khẩu"
            rules="required"
          >
            <b-form-group
              label="Mật khẩu"
              label-for="mat_khau"
            >
              <b-form-input
                id="mat_khau"
                v-model="userData.mat_khau"
                type="password"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Mật khẩu đăng nhập"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Họ tên -->
          <validation-provider
            #default="validationContext"
            name="Họ tên"
            rules="required"
          >
            <b-form-group
              label="Họ tên"
              label-for="ho-ten"
            >
              <b-form-input
                id="full-name"
                v-model="userData.hoten"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Họ và tên đầy đủ"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Điện thoại -->
          <validation-provider
            #default="validationContext"
            name="Điện thoại"
            rules="required"
          >
            <b-form-group
              label="Điện thoại"
              label-for="dien-thoai"
            >
              <b-form-input
                id="dien-thoai"
                v-model="userData.dien_thoai"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="0988xxxxxx"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                placeholder="Email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="form-group m-checkbox-inline mb-0 custom-radio-ml">
            <b-form-checkbox-group
              v-model="userData.vai_tro"
              :options="listVaiTro"
              class="checkbox-dark checkbox"
              value-field="item"
              text-field="name"
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="CloudIcon"
                class="mr-50"
              />
              Lưu lại
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Hủy
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BFormCheckboxGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  mounted() {
    const currentUser = getUserData()

    this.$root.$on('updateThanhVien', id => {
      store.state.showBlock = true
      store
        .dispatch('app-user/getKhachHang', {
          auth: currentUser.auth_key,
          uid: currentUser.id,
          khach_hang: id,
          thanh_vien: 1,
        })
        .then(response => {
          this.userData = response.data.user
          this.titleForm = 'Sửa thông tin thành viên'
          Object.assign(this.userData, {
            mat_khau: response.data.user.password_hash,
            vai_tro: response.data.vai_tro_user,
          })

          store.state.showBlock = false
        })
        .catch(e => {
          store.state.showBlock = false
          this.showToast('danger', 'Thông báo', 'BellIcon', e.message)
        })
    })
  },
  created() {

  },
  setup(props, { emit }) {
    const currentUser = getUserData()
    const toast = useToast()
    const titleForm = 'Thông tin người dùng'

    const blankUserData = {
      id: '',
      hoten: '',
      dien_thoai: '',
      ngay_sinh: '',
      email: '',
      khu_vuc_id: '',
      dai_ly_id: '',
      dai_ly: 0,
      khach_hang: 1,
      vai_tro: [],
      mat_khau: '111',
    }

    const listVaiTro = ref([])

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const setUserData = user => {
      userData.value = user
    }
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }

    const onSubmit = () => {
      store.state.showBlock = true
      Object.assign(userData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        khach_hang: 0,
        dai_ly: 0,
        thanh_vien: 1,
      })
      store.dispatch('app-user/addKhachHang', userData.value)
        .then(response => {
          showToast('success', 'Thông báo', 'BellIcon', response.data.content)
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          store.state.showBlock = false
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }
    store.state.showBlock = true
    store.dispatch('app-user/getListVaiTro', {
      auth: currentUser.auth_key,
      uid: currentUser.id,
    })
      .then(response => {
        listVaiTro.value = response.data.optionVaiTro
        store.state.showBlock = false
      })
      .catch(e => {
        if (typeof e.response !== 'undefined') {
          showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
        } else if (typeof e.message !== 'undefined') {
          showToast('danger', 'Thông báo', 'BellIcon', e.message)
        } else {
          showToast('danger', 'Thông báo', 'BellIcon', e)
        }
        store.state.showBlock = false
      })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      titleForm,
      listVaiTro,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      setUserData,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
